import './back-to-top.scss';
import { smoothScrollTo } from '../../utilities/js/helper';

class BackToTop {
    constructor ($backToTop) {
        this.$backToTop = $backToTop;
    }

    init () {
        this.bindEvents();
    }

    bindEvents () {
        this.$backToTop.addEventListener('click', () => {
            smoothScrollTo(0, 0);
        });
    }
}

export { BackToTop };
