import AjaxLoad from '../../utilities/js/ajax-load';
import { loadScript } from '../../utilities/js/helper';

class FormHandler {

    constructor(element, options) {

        const settings = {
            initAttr: 'data-form', 
            recaptchaAttr: 'recaptcha',
            buttonAttr: '[type="submit"]',
            onSubmitted: null
        };

        this.settings = Object.assign({}, settings, options);

        this.$form = element;
        this.$recaptcha = this.$form.querySelector('[' + this.settings.initAttr + '="' + this.settings.recaptchaAttr + '"]');
        this.recaptcha = false;
        
        if(this.settings.loader !== null) {
            this.$loader = _createElementFromHTML(this.settings.loader);
        }

        this.initialize();
    }


    initialize() {
        if (this.$recaptcha) {
            this.loadRecaptcha();
        }

        this.setEvents();
    }

    loadRecaptcha () {
        this.recaptchaKey = this.$recaptcha.getAttribute('data-key');
        if (this.recaptchaKey !== '') {
            loadScript('//www.google.com/recaptcha/api.js').then(() => {
                this.initRecaptcha(); 
            }, () => {
                console.error('fail to load script');
            });
        }
    }

    initRecaptcha () {
        window.grecaptcha.ready(() => {
            this.recaptcha = true;

            this.renderRecaptcha();
        });   
    }

    renderRecaptcha () {
        this.recaptchaId = window.grecaptcha.render(this.$recaptcha, {
            sitekey: this.recaptchaKey,
            size: 'invisible',
            callback: () => {
                this.submitForm();
            }
        });
    }

    submitForm($button) {

        const $form = this.$form.querySelector('form');

        if($form === null) {
            return;
        }

        const formAction = $form.getAttribute('action'), 
              formMethod = $form.getAttribute('method'), 
              formData = new FormData($form);

        if($button) {
            formData.append($button.getAttribute('name'), $button.getAttribute('value'));
        }

        new AjaxLoad(formAction, {
            requestMethod: formMethod, 
            requestData: formData, 
            onSuccess: (resultText) => {

                this.$form.classList.add('is--hidden');
                
                const $result = _createElementFromHTML(resultText);
                const $resultContent = $result.innerHTML;

                window.setTimeout(() => {

                    const $overlay = this.$form.closest('[data-overlay="overlay"]');
                    const $overlayWrapper = $overlay.querySelector('[data-overlay="wrapper"]');

                    if($overlay !== null) {
                        $overlayWrapper.scrollTop = 0;
                        const customOverlay = $result.getAttribute('data-overlay-style');
                        console.log($result);
        
                        if (customOverlay) {
                            $overlay.setAttribute('data-overlay-style', customOverlay);
                        } else {
                            $overlay.removeAttribute('data-overlay-style');
                        }
                    }
                    
                    this.$form.innerHTML = $resultContent;
                    this.$form.classList.remove('is--hidden');

                    this.$recaptcha = this.$form.querySelector('[' + this.settings.initAttr + '="' + this.settings.recaptchaAttr + '"]');
                    if (this.$recaptcha && this.recaptcha === true) {
                        this.renderRecaptcha();
                    }

                    if(typeof this.settings.onSubmitted === 'function') {
                        this.settings.onSubmitted(this.$form);
                    }
                }, 500);
            }
        });
    }

    setEvents() {
        this.$form.addEventListener('submit', (e) => {

            if(e.target.tagName.toLowerCase() === 'form') {
                if (this.recaptcha === true) {
                    window.grecaptcha.execute();
                } else {
                    this.submitForm();
                }
            }
            e.preventDefault();
        });

        this.$form.addEventListener('click', (e) => {

            if(e.target.tagName.toLowerCase() === 'button') {

                const $button = e.target;

                $button.setAttribute('disabled', true);
                
                if(this.settings.loader !== null) {
                    $button.appendChild(this.$loader);
                }

                if($button.getAttribute('value') === 'back') {
                    if (this.recaptcha === true) {
                        window.grecaptcha.execute();
                    } else {
                        this.submitForm($button);
                    }
                } else {
                    if (this.recaptcha === true) {
                        window.grecaptcha.execute();
                    } else {
                        this.submitForm();
                    }
                }


                e.preventDefault();
            }

        });
    }
}

export { FormHandler };
