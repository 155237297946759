import './scrolltable.scss';

class Scrolltable {

    constructor(element, options) {

        const defaults = {
            offset: 4            
        };

        this.settings = Object.assign({}, defaults, options);

        this.$table = element;

        window.setTimeout(() => {
            this.initialize();
        }, 150);
    }

    initialize() {

        let $scroller = document.createElement('div');
        let $scrollerWrapper = document.createElement('div');        
        let $scrollTable = document.createElement('div');

        const buildWrapper = () => {

            $scroller.classList.add('scrolltable__scroller');
            this.$table.parentNode.insertBefore($scroller, this.$table);
            $scroller.appendChild(this.$table);

            $scrollerWrapper.classList.add('scrolltable__scrollerwrapper');
            $scroller.parentNode.insertBefore($scrollerWrapper, $scroller);
            $scrollerWrapper.appendChild($scroller);

            
            $scrollTable.classList.add('scrolltable');
            $scrollerWrapper.parentNode.insertBefore($scrollTable, $scrollerWrapper);
            $scrollTable.appendChild($scrollerWrapper);
        };

        buildWrapper();

        if(this.$table.offsetWidth > $scroller.offsetWidth) {
            $scrollerWrapper.classList.add('has--scroll');
        }

        $scroller.addEventListener('scroll', () => {
            if($scroller.scrollLeft > this.settings.offset) {
                $scrollerWrapper.classList.add('is--start');
            } else {
                $scrollerWrapper.classList.remove('is--start');
            }

            if($scroller.scrollLeft >= $scroller.scrollWidth - $scroller.offsetWidth - this.settings.offset) {
                $scrollerWrapper.classList.add('is--end');
            } else {
                $scrollerWrapper.classList.remove('is--end');
            }
        });
    }
}

export { Scrolltable };


